<template>
  <div>
    <v-col>
      <v-btn v-if="checkPermission([128, 8, 4])" @click="dialog = true" color="#e21a1a" class="white--text">
        <v-icon left>mdi-plus</v-icon>Добавить категорию</v-btn
      >
    </v-col>
    <v-data-table :headers="headers" :items="categories" disable-pagination hide-default-footer class="ma-3">
      <template v-slot:item.nameRu="{ item }">
        <tr>
          <td>
            <v-text-field v-model="item.nameRu" background-color="transparent" flat hide-details="auto" placeholder="Введите наименование" solo />
          </td>
        </tr>
      </template>
      <template v-slot:item.position="{ item }">
        <tr>
          <td>
            <v-text-field
              v-model="item.position"
              :rules="validationRules.notEmpty"
              background-color="transparent"
              flat
              hide-details="auto"
              placeholder="Введите позицию"
              solo
              type="number"
              hide-spin-buttons
            />
          </td>
        </tr>
      </template>
      <template v-slot:item.isActive="{ item }">
        <tr>
          <td>
            <v-autocomplete
              v-model="item.isActive"
              :items="isActivePropertyList()"
              background-color="transparent"
              flat
              hide-details="auto"
              placeholder=""
              solo
              color="#e21a1a"
            />
          </td>
        </tr>
      </template>
      <template v-slot:item.actions="{ item }">
        <tr>
          <td>
            <v-btn @click="editCategoryClick(item)" :disabled="itemStatus(item)" color="#e21a1a" text>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-form ref="form" @submit.prevent="checkInput">
          <v-card-title>Новая категория</v-card-title>
          <v-card-text>
            <v-text-field
              class="my-3"
              v-model="newCategory.nameRu"
              :rules="validationRules.notEmpty.concat(validationRules.nameForm)"
              outlined
              dense
              label="Название"
            ></v-text-field>
            <v-text-field
              v-model.number="newCategory.position"
              :rules="validationRules.notEmpty.concat(validationRules.positionForm)"
              outlined
              dense
              hide-spin-buttons
              label="Позиция"
              type="number"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="d-flex flex-wrap">
            <v-btn class="white--text my-1 ml-auto" type="submit" depressed color="#e21a1a" @click="addCategoryClick">СОХРАНИТЬ</v-btn>
            <v-btn text class="mx-2 text-none my-1" color="grey darken-3" @click="dialog = false">ОТМЕНА</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import Notification from '@/components/Notification';
export default {
  data() {
    return {
      newCategory: {
        nameRu: '',
        position: '',
        cafeToken: this.$route.params.cafeToken
      },
      completeMessage: '',
      completeDialog: false,
      dialog: false,
      snackbarValue: false,
      currentPath: this.$route.path,
      validationRules: {
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле'],
        nameForm: [
          (value) => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        positionForm: [(v) => (v > 0 && v <= 1000) || 'Некорректные данные (макс. 1000)']
      }
    };
  },
  components: { Notification },
  computed: {
    headers() {
      return [
        { text: 'Название', value: 'nameRu', align: 'start' },
        { text: 'Позиция', value: 'position' },
        { text: 'Категория активна', value: 'isActive' },
        { text: '', value: 'actions', sortable: false }
      ];
    },
    categories() {
      let categoriesArray = [];
      if (this.categoriesList !== undefined && this.currentCafe !== null && this.categoriesList.length > 0) {
        categoriesArray = JSON.parse(JSON.stringify(this.categoriesList));
      }
      return categoriesArray.sort((a, b) => a.position - b.position);
    },
    products() {
      const itemsArray = [];
      if (this.menuList !== undefined && this.menuList !== null && this.menuList.length > 0) {
        this.menuList.forEach((item) => itemsArray.push(item));
      }
      return itemsArray;
    },
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('admin', ['menuList']),
    ...mapGetters('admin', ['isMenuListLoading']),
    ...mapGetters('admin', ['menuListResult']),
    ...mapGetters('admin', ['isMenuListError']),
    ...mapGetters('admin', ['categoriesList']),
    ...mapGetters('admin', ['menuListErrorMessage']),
    ...mapGetters('category', ['addCategoryErrorMessage']),
    ...mapGetters('category', ['editCategoryErrorMessage'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    checkInput() {
      return true;
    },
    getMenuList() {
      // this.fetchMenuList(this.$route.params.cafeToken);
    },
    getCategoriesList() {
      this.fetchCategories(this.$route.params.cafeToken);
    },
    isActivePropertyList() {
      return ReferenceHelper.getActiveProperty();
    },
    itemStatus(item) {
      let defaultCategory = this.categoriesList.find((category) => category.token === item.token);
      let status = true;
      for (let prop in item) {
        if (item[prop] !== defaultCategory[prop]) {
          status = false;
        }
      }
      return status;
    },
    editCategoryClick(category) {
      let obj = {
        cafeToken: this.$route.params.cafeToken,
        categories: []
      };
      obj.categories.push(category);
      this.editCategory(obj)
        .then(() => {
          this.showNotification('Категория отредактирована', 'success', '2000', 'mdi-check');
          this.getCategoriesList();
        })
        .catch(() => {
          this.showNotification(this.editCategoryErrorMessage, 'error', '5000', 'mdi-alert-circle-outline');
          this.getCategoriesList();
        });
    },
    addCategoryClick() {
      if (this.$refs.form.validate()) {
        this.addCategory(this.newCategory)
          .then(() => {
            this.getCategoriesList();
            this.closeDialog();
            this.showNotification('Категория добавлена', 'success', '2000', 'mdi-check');
          })
          .catch(() => {
            this.showNotification(this.addCategoryErrorMessage, 'error', '5000', 'mdi-alert-circle-outline');
            this.getCategoriesList();
          });
      }
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    closeDialog() {
      this.dialog = false;
      this.newCategory.nameRu = '';
      this.newCategory.position = '';
    },
    ...mapActions('admin', ['fetchMenuList']),
    ...mapActions('admin', ['fetchCategories']),
    ...mapActions('category', ['addCategory']),
    ...mapActions('category', ['editCategory']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.getMenuList();
    // this.getCategoriesList();
  }
};
</script>
<style scoped>
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
</style>
